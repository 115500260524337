





.travelBody {
    margin-top: 0;

}

.travelBody .path {
    width: 2rem;
    min-height: 10vh;
    background-image: repeating-linear-gradient(rgb(0, 0, 0), rgba(255, 255, 255, 0) 2rem, #ffffff00 6rem, #CCC 2rem);
}

.headerHeading {
   margin-top: 10%;
    color: white;
    line-height: 2.1rem;
    font-family: 'Great Vibes', cursive;
    width: 100%;
    
}

.bodyHeader {
    position: relative;
    width: 60%;
    margin-left: 2rem;
    margin-bottom: 1rem;
    font-size: xx-large;
    font-family: 'Great Vibes', cursive;
    font-family: 'Montaga', serif;
}

.stepperContainer {
    margin-left: 5rem;

}

.stepHeader {
    font-family: 'Lobster', cursive;
}


.travelContainer{
   
   
}

/*Footer*/
.travelFooter {

margin: 0.5rem;
padding:2rem ;
}