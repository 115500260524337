.notification_open {
    animation: bounce-in 0.5s ease-in;
}

.notification_close {
    animation: bounce-out 0.5s ease-in;
}


@keyframes bounce-in {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes bounce-out {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(0.5);
    }
}